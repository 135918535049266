<template>
  <div :class="classText">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'main',
    },
  },
  computed: {
    classText() {
      return `text ${this.className}  ${this.color}`
    },
  },
}
</script>

<style scoped lang="sass">
.text
  font-family: Roboto, sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 22px
  margin-bottom: 8px
  margin-top: 8px
.mobileHeader
  color: #303133
  font-family: Roboto, sans-serif
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 36px
.desktopHeader
  font-weight: bold
  font-size: 24px
  line-height: 36px
  color: #606266
</style>
